<template>
  <div class="container">
    <form>
      <label v-for="(item, index) in controls" @click="activeFun(item, index)">
        <input type="radio" name="radio" :checked="index === activeVar" />
        <span>{{ item.name }}</span>
      </label>
    </form>
    <!-- 楼层返回 -->
    <div
      class="back animated fadeIn"
      @click="backFloorBase"
      v-if="isShowFloorBack"
    >
      <img src="./../assets/image/back.png" alt="" />
      <p>返回</p>
    </div>
    <!-- 楼层ui -->
    <layer
      :layers="layerData"
      :active="currentLayer"
      :styles="{ top: '55%', left: '72%', height: '400px' }"
      @change="changeLayer"
      v-if="isShowFloorBack"
    ></layer>
    <tooltip
      :style="{
        visibility: roomTooltipStyle.show ? 'visible' : 'hidden',
        left: roomTooltipStyle.x + 'px',
        top: roomTooltipStyle.y + 'px',
      }"
      :data="roomTooltipStyle"
    ></tooltip>
  </div>
</template>

<script>
import { loaderFloorManage, setModelLayer } from "@/three/floorManage";
import {
  loaderParkElectricity,
  destroyParkElectricity,
} from "@/three/parkElectricity";
import layer from "@/components/layer";
import {
  destroyControlGroup,
  setModelDefaultMatrial,
} from "@/three/loaderModel";
import tooltip from "@/components/tooltip";
import { parkData, cameraUrls } from "@/assets/mock/mock";
import { loaderParkWater, destroyParkWater } from "@/three/parkWater";
import gsap from "gsap";
let movingList = ["house", "guanzi", "fangzi"];
export default {
  name: "",
  components: {
    layer,
    tooltip,
  },
  props: {},
  data() {
    return {
      roomTooltipStyle: {
        show: false,
        x: 0,
        y: 0,
        name: "xxx",
      },
      isShowFloorBack: false,
      layerData: [],
      currentLayer: "全楼",
      curFloorModel: null,
      controls: [
        {
          name: "首页",
          goFunction: () => {
            window.app.flyTo({
              position: app.cameraPostion,
              controls: app.controlsTarget,
              duration: 1000,
            });
          },
          backFunction: () => {
            window.app.initRaycaster((activeObj) => {
              console.log(activeObj);
              if (activeObj.object.parent) {
                const target = this.findParentName(activeObj.object);
                movingList.forEach((item) => {
                  if (target.name.includes(item)) {
                    console.log("进了");
                    gsap.to(window.app.camera.position, {
                      x: target.position.x + 30,
                      y: target.position.y + 30,
                      z: target.position.z + 30,
                      duration: 2,
                      ease: "none",
                    });
                    gsap.to(window.app.controls.target, {
                      x: target.position.x,
                      y: target.position.y,
                      z: target.position.z,
                      duration: 2,
                      ease: "none",
                    });
                  }
                });
              }
            });
          },
        },
        {
          name: "楼层管理",
          goFunction: () => {
            loaderFloorManage(window.app);
          },
          backFunction: () => {
            destroyControlGroup(window.app, "floorText-3d");
            this.isShowFloorBack = false;
            this.roomTooltipStyle.show = false;
            if (this.curFloorModel && this.currentLayer !== "全楼") {
              this.currentLayer = "全楼";
              setModelLayer(
                window.app,
                this.curFloorModel,
                this.currentLayer,
                this.layerData,
                () => {
                  setModelDefaultMatrial(window.app);
                  this.curFloorModel = null;
                }
              );
            } else {
              setModelDefaultMatrial(window.app);
            }
          },
        },
        {
          name: "电力监测",
          goFunction: () => {
            loaderParkElectricity(window.app);
          },
          backFunction: () => {
            destroyParkElectricity(window.app);
          },
        },
        {
          name: "水力监测",
          goFunction: () => {
            loaderParkWater(window.app);
          },
          backFunction: () => {
            destroyParkWater(window.app);
          },
        },
      ],
      activeVar: 0,
    };
  },
  watch: {
    activeVar(newVal, oldVal) {
      const oldControl = this.controls.filter(
        (item) => item.name === this.controls[oldVal].name
      );
      oldControl[0].backFunction();
      const newControl = this.controls.filter(
        (item) => item.name === this.controls[newVal].name
      );
      newControl[0].goFunction();
    },
  },
  methods: {
    findParentName(child) {
      //找到child的最上级的name不为""且不为"Group"开头的父级
      let parent = child.parent;
      while (parent) {
        if (parent.name && !parent.name.includes("Group")) {
          return parent;
        }
        parent = parent.parent;
      }
      return "";
    },
    activeFun(item, index) {
      this.activeVar = index;
    },
    // 返回到楼层初始化状态
    backFloorBase() {
      this.isShowFloorBack = false;
      this.roomTooltipStyle.show = false;

      if (this.curFloorModel && this.currentLayer !== "全楼") {
        this.currentLayer = "全楼";
        setModelLayer(
          window.app,
          this.curFloorModel,
          this.currentLayer,
          this.layerData,
          () => {
            setModelDefaultMatrial(window.app);
            loaderFloorManage(window.app);
            this.curFloorModel = null;
          }
        );
      } else {
        setModelDefaultMatrial(window.app);
        loaderFloorManage(window.app);
      }
    },
    // 改变楼层函数
    changeLayer(layer) {
      this.currentLayer = layer;
      this.roomTooltipStyle.show = false;
      setModelLayer(window.app, this.curFloorModel, layer, this.layerData);
    },
  },
  mounted() {
    this.$EventBus.$on("changeFloorUI", (obj) => {
      console.log('当前事件的全部', obj)
      this.isShowFloorBack = obj.isShowFloorBack;
      this.curFloorModel = obj.model;
      const layerNames = obj.model.children
        .filter((item) => item.name.indexOf("F") > -1)
        .map((item) => {
          const name = item.name.substr(0, item.name.indexOf("F") + 1);
          return name;
        });
      console.log(layerNames, '处理后的楼层')
      this.currentLayer = "全楼";
      this.layerData = [this.currentLayer].concat(layerNames);
    });

    this.$EventBus.$on("changeRoomTooltip", (obj) => {
      const data = parkData[this.curFloorModel.name];
      if (obj.name.indexOf("摄像头") > -1) {
        this.roomTooltipStyle = Object.assign(
          {
            楼栋: this.curFloorModel.name,
            楼层: this.currentLayer,
            摄像头: obj.name,
            视频: cameraUrls[obj.name.substr(0, 4)],
          },
          obj
        );
      } else {
        const roomName = obj.name.substr(0, 3);
        this.roomTooltipStyle = Object.assign(
          {
            楼栋: this.curFloorModel.name,
            楼层: this.currentLayer,
            房间号: roomName,
            度数: data[this.currentLayer][roomName][obj.type],
          },
          obj
        );
      }
    });

    this.$EventBus.$on("changeTooltip", (obj) => {
      this.roomTooltipStyle = obj;
    });
  },
};
</script>
<style lang="less" scoped>
.back {
  width: 48px;
  position: fixed;
  bottom: 4%;
  left: 25%;
  z-index: 3;
  cursor: pointer;
  font-size: 18px;
  img {
    width: 100%;
  }
  p {
    color: #fff;
    text-align: center;
  }
}
.container form {
  display: flex;
  flex-wrap: wrap;
}

.container label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
}

.container label input {
  position: absolute;
  left: -9999px;
}

.container label input:checked + span {
  background-color: #414181;
  color: white;
}

.container label input:checked + span:before {
  box-shadow: inset 0 0 0 0.4375em #00005c;
}

.container label span {
  display: flex;
  align-items: center;
  padding: 0.375em 0.75em 0.375em 0.375em;
  border-radius: 99em;
  transition: 0.25s ease;
  color: #414181;
}

.container label span:hover {
  background-color: #d6d6e5;
}

.container label span:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin-right: 0.375em;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.125em #00005c;
}
</style>
