<template>
  <div class="main">
    <loader :number="loadingNumber" v-if="loadingNumber !== 100"></loader>
    <!-- <div class="mask"></div> -->
    <div v-if="loadingNumber === 100 && isShowChart">
      <big-header></big-header>
      <big-left v-if="showPrimitiveChart" class="left"></big-left>
      <div class="page_main_left" else>
        <div v-for="(item, index) in 3" class="show_three" :key="index" style="margin-bottom:30px;">
          <div ref="statisticMap" style="width: 100%;height: 100%"></div>
        </div>
      </div>
      <big-right v-if="showPrimitiveChart" class="right"></big-right>
      
      <div class="page_main_right" else>
        <div v-for="(item, index) in 3 " class="show_three" :key="index" style="margin-bottom:30px;">
          <div ref="statisticMap" style="width: 100%;height: 100%"></div>
        </div>
      </div>
      <big-top class="top"></big-top>
      <big-control class="control"></big-control>
    </div>
    <div id="screen" class="screen"></div>
  </div>
</template>

<script>
import loader from "@/components/loader";
import ZThree from "@/three/ZThree";
import * as THREE from "three";
import { loaderModel } from "@/three/loaderModel";
import { CSS3DRenderer } from "three/examples/jsm/renderers/CSS3DRenderer";
import { cssRender } from "@/three/cssRender";
import bigHeader from "@/components/header";
import bigLeft from "@/components/left";
import bigRight from "@/components/right";
import bigTop from "@/components/top";
import {
  secondaryTexture,
  trunkTexture,
  primaryTexture,
  // skyTexture,
} from "@/three/texture";
import * as echarts from 'echarts'
import TWEEN from "three/examples/jsm/libs/tween.module.js";
import bigControl from "@/components/bigControl";
import { darkMaterial } from "@/three/material";
import { createReprocessing } from "@/three/reprocessing";
import gsap from "gsap";
import { setToken } from '@/utils/auth'
import {
  getDevice,
  cacheJsonThingsModel,
  getDeviceRunningStatus,
  listHistory,
  getDeviceMain,
  getDeviceRun,
  waterGasAnalyze,
  environmentAnalyze,
  alarmsNumber,
  alarmsNumberAnalyze,
  getdeviceSignal
} from '@/api/index'

let app,
  camera,
  scene,
  renderer,
  controls,
  clock,
  reprocessing,
  materials = {},
  movingList = ["house", "guanzi", "fangzi"];
export default {
  name: "Home",
  components: {
    loader,
    bigHeader,
    bigLeft,
    bigRight,
    bigTop,

    bigControl,
  },
  data() {
    return {
      // loading数值
      loadingNumber: 0,
      isShowChart: false,
      // 是否显示原始图表
      showPrimitiveChart: true,
      // 表单参数
      form: {
        productId: 0,
        status: 1,
        locationWay: 1,
        firmwareVersion: 1.0,
        serialNumber: '',
        deviceType: 1,
        isSimulate: 0
      },
      // 设备摘要
      summary: [],
      // 用于判断是否是设备组(modbus)
      isSubDev: false,
      // 设备开始状态
      oldDeviceStatus: null,
      // 设备信息
      deviceInfo: {},
      // 查询参数
      queryParams: {
        serialNumber: null,
        identity: '',
        total: 200,
        slaveId: undefined
      },
      // 子设备列表
      slaveList: [],
      // 统计物模型
      staticList: [],
      staticListL: [],
      staticListR: [],
      // 图表集合
      chart: [],
      leftCenterX: ['3/20', '3/21', '3/22', '3/23', '3/24', '3/25', '3/26'],
      leftCenterY: [
        {
          name: '测试数据',
          type: 'line',
          data: [7, 11, 22, 16, 33, 12, 28],
          showSymbol: false,
          smooth: true,
          areaStyle: {
            opacity: 0.2,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 1,
                color: 'rgba(102, 165, 255, 0.80)'
              },
              {
                offset: 0,
                color: 'rgba(102, 165, 255, 0)'
              }
            ])
          }
        }
      ],
    };
  },
  watch: {
    /* eslint-disable */
    // 获取到父组件传递的device后
    form: function (newVal, oldVal) {
      this.deviceInfo = newVal
      if (this.deviceInfo && this.deviceInfo.deviceId !== 0) {
        console.log('开始绘制页面', this.deviceInfo)
        this.isSubDev = this.deviceInfo.subDeviceList && this.deviceInfo.subDeviceList.length > 0
        this.queryParams.slaveId = this.deviceInfo.slaveId
        this.queryParams.serialNumber = this.deviceInfo.serialNumber
        this.slaveList = newVal.subDeviceList
        // 监测数据
        if (this.isSubDev) {
          this.staticList = this.deviceInfo.cacheThingsModel['properties'].filter(item => {
            return item.tempSlaveId === this.queryParams.slaveId
          })
          if(this.staticList.length>5) {
            this.staticListL = this.staticList.slice(0, 3)
            this.staticListR = this.staticList.slice(3, 6)
          } else {
            this.staticListL = this.staticList.slice(0, 3)
            this.staticListR = this.staticList.slice(3)
          }
        } else {
          this.staticList = this.deviceInfo.staticList
          if(this.staticList.length>5) {
            this.staticListL = this.staticList.slice(0, 3)
            this.staticListR = this.staticList.slice(3, 6)
          } else {
            this.staticListL = this.staticList.slice(0, 3)
            this.staticListR = this.staticList.slice(3)
          }

        }
        if(this.staticList.length === 0) {
          this.getStatistics()
          this.$message({
          message: '暂无数据',
          type: 'warning'
        });
          return false
        }
        // 加载图表
        this.$nextTick(function () {
          // 绘制图表
          this.getStatistic()
          // 获取统计数据
          this.getListHistory()
        })
      }
    }
  },
  methods: {
    async initZThree() {
      app = new ZThree("screen");
      app.initThree();
      // app.initHelper();
      app.initLight();

      app.initOrbitControls();
      app.cameraPostion = [98.23, 47.4, 95.36];
      app.controlsTarget = [0, 2.53, 2.88];

      window.app = app;
      camera = app.camera;
      camera.position.set(...app.cameraPostion);
      scene = app.scene;
      scene.fog = new THREE.Fog("#000000", 100, 480);
      // 天空盒
      // const skyBox = app.loaderSky();
      // scene.background = skyBox;
      renderer = app.renderer;
      renderer.outputEncoding = THREE.sRGBEncoding;
      renderer.toneMapping = THREE.ACESFilmicToneMapping;
      renderer.toneMappingExposure = 0.73;
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      // renderer.logarithmicDepthBuffer = true;
      controls = app.controls;
      controls.target.set(...app.controlsTarget);
      controls.maxPolarAngle = Math.PI / 2.2;

      clock = new THREE.Clock();
      reprocessing = createReprocessing(app);
      console.log(reprocessing, 123);
      await loaderModel(app);
      this.showPrimitiveChart = app.isPrimitiveModel === true
      let instance = new cssRender(CSS3DRenderer, app);
      app.cssRenderer = instance.cssRenderer;
      app.instance = instance;
      // app.initRaycaster((activeObj) => {
      //   console.log(activeObj);
      //   if (activeObj.object.parent) {
      //     const target = this.findParentName(activeObj.object);
      //     movingList.forEach((item) => {
      //       if (target.name.includes(item)) {
      //         // 此方法传入设备id
      //         this.getDevice()
      //         console.log("进了111", target);
      //         gsap.to(camera.position, {
      //           x: target.position.x + 30,
      //           y: target.position.y + 30,
      //           z: target.position.z + 30,
      //           duration: 2,
      //           ease: "none",
      //         });
      //         gsap.to(controls.target, {
      //           x: target.position.x,
      //           y: target.position.y,
      //           z: target.position.z,
      //           duration: 2,
      //           ease: "none",
      //         });
      //       }
      //     });
      //   }
      // });
      app.render(() => {
        if (this.isShowChart) {
          // 道路贴图
          if (secondaryTexture) {
            secondaryTexture.offset.x -= 0.005;
          }

          if (trunkTexture) {
            trunkTexture.offset.x -= 0.005;
          }

          if (primaryTexture) {
            primaryTexture.offset.x -= 0.005;
          }
        }

        const delta = clock.getDelta();

        controls.update(delta);

        scene.traverse(this.darkenNonBloomed);
        reprocessing.render();
        scene.traverse(this.restoreMaterial);
        app.finalComposer.render();
        app.cssRenderer.render(scene, camera);
        TWEEN.update();
      });
    },
    findParentName(child) {
      //找到child的最上级的name不为""且不为"Group"开头的父级
      let parent = child.parent;
      while (parent) {
        if (parent.name && !parent.name.includes("Group")) {
          return parent;
        }
        parent = parent.parent;
      }
      return "";
    },
    darkenNonBloomed(obj) {
      if (obj.isMesh && app.bloomLayer.test(obj.layers) === false) {
        materials[obj.uuid] = obj.material;
        obj.material = darkMaterial;
      }
    },
    restoreMaterial(obj) {
      if (materials[obj.uuid]) {
        obj.material = materials[obj.uuid];
        delete materials[obj.uuid];
      }
    },
    /**获取设备详情*/
    getDevice (deviceId = '118') {
      getDevice(deviceId).then(async response => {
        // 获取设备状态和物模型
        this.getDeviceStatusWitchThingsModel(response)
      })
    },
    /** 获取设备状态和物模型 **/
    async getDeviceStatusWitchThingsModel (response) {
        // 获取缓存物模型
        response.data.cacheThingsModel = await this.getCacheThingsModdel(response.data.productId)
        // 获取设备运行状态
        response.data.thingsModels = await this.getDeviceStatus(response.data)
        // 格式化物模型，拆分出监测值,数组添加前缀
        this.formatThingsModel(response.data)
        this.form = response.data
        // 解析设备摘要
        if (this.form.summary != null && this.form.summary != '') {
            this.summary = JSON.parse(this.form.summary)
        }
        this.isSubDev = this.form.subDeviceList && this.form.subDeviceList.length > 0
        this.oldDeviceStatus = this.form.status
        //Mqtt订阅
        this.connectMqtt()
        this.mqttSubscribe(this.form)
    },
    /* 连接Mqtt消息服务器 */
    async connectMqtt () {
      if (this.$mqttTool.client === null) {
        await this.$mqttTool.connect()
      }
      this.mqttCallback()
    },
    /** Mqtt订阅主题 */
    mqttSubscribe (device) {
      // 订阅当前设备状态和实时监测
      let topicStatus = '/' + device.productId + '/' + device.serialNumber + '/status/post'
      let topicProperty = '/' + device.productId + '/' + device.serialNumber + '/property/post'
      let topicFunction = '/' + device.productId + '/' + device.serialNumber + '/function/post'
      let topicMonitor = '/' + device.productId + '/' + device.serialNumber + '/monitor/post'
      let topics = [];
      //订阅ws推送
      if (device.subDeviceList && device.subDeviceList.length > 0 && this.isSubDev) {
        device.subDeviceList.forEach(value => {
          let devTopic = '/' + device.productId + '/' + value.serialNumber + '/ws/service'
          topics.push(devTopic)
        })
      } else {
        //兼容TCP
        let serviceTop = '/' + device.productId + '/' + device.serialNumber + '/ws/service'
        topics.push(serviceTop)
      }
      topics.push(topicStatus)
      topics.push(topicFunction)
      topics.push(topicMonitor)
      /*modbus设备不订阅此topic*/
      if (!this.isSubDev) {
        topics.push(topicProperty)
      }
      this.$mqttTool.subscribe(topics)
    },
    /* Mqtt回调处理  */
    mqttCallback () {
      this.$mqttTool.client.on('message', (topic, message, buffer) => {
        let topics = topic.split('/')
        let productId = topics[1]
        let deviceNum = topics[2]
        message = JSON.parse(message.toString())
        if (!message) {
          return false
        }
        if (topics[3] === 'status' || topics[2] === 'status') {
          console.log('接收到【设备状态-详情】主题：', topic)
          console.log('接收到【设备状态-详情】内容：', message)
          // 更新列表中设备的状态
          if (this.form.serialNumber == deviceNum) {
            this.oldDeviceStatus = message.status
            this.form.status = message.status
            this.form.isShadow = message.isShadow
            this.form.rssid = message.rssid
          }
        }
        //不是modbus不转发到子页面，其他设备的页面有回调方法
        if (this.isSubDev) {
          /*发送设备上报到子模块*/
          if (topic.endsWith('ws/service')) {
            console.log('接收到【设备数据上报】主题：', topic)
            console.log('接收到【设备数据上报】消息：', message)
            this.$busEvent.$emit('updateData', {
              serialNumber: topics[2],
              productId: this.form.productId,
              data: message,
            })
          }
        }
        /*发送设备上报到子模块*/
        if (topic.endsWith('ws/post/simulate')) {
          // console.log('接收到【modbus报文上报】主题：', topic)
          // console.log('接收到【modbus报文上报】主题：', message)
          this.$busEvent.$emit('logData', {
            serialNumber: topics[1],
            productId: this.form.productId,
            data: message,
          })
        }
      })
    },
    /** 获取缓存物模型*/
    getCacheThingsModdel (productId) {
        return new Promise((resolve, reject) => {
            cacheJsonThingsModel(productId).then(response => {
                resolve(JSON.parse(response.data))
            }).catch(error => {
                reject(error)
            })
        })
    },
    /**获取设备运行状态*/
    getDeviceStatus (data) {
      const params = {
          'deviceId': data.deviceId,
          'slaveId': data.slaveId
      }
      return new Promise((resolve, reject) => {
        getDeviceRunningStatus(params).then(response => {
          resolve(response.data.thingsModels)
        }).catch(error => {
          reject(error)
        })
      })
    },
    formatThingsModel (data) {
      data.chartList = []
      data.monitorList = []
      data.staticList = []
      // 物模型格式化
      for (let i = 0; i < data.thingsModels.length; i++) {
        // 数字类型设置默认值并转换未数值
        if (data.thingsModels[i].datatype.type == 'integer' || data.thingsModels[i].datatype.type == 'decimal') {
          if (data.thingsModels[i].shadow == '') {
            data.thingsModels[i].shadow = Number(data.thingsModels[i].datatype.min)
          } else {
            data.thingsModels[i].shadow = Number(data.thingsModels[i].shadow)
          }
        }

        // 物模型分类放置
        if (data.thingsModels[i].datatype.type == 'array') {
          if (data.thingsModels[i].datatype.arrayType == 'object') {
            for (let k = 0; k < data.thingsModels[i].datatype.arrayParams.length; k++) {
              for (let j = 0; j < data.thingsModels[i].datatype.arrayParams[k].length; j++) {
                // 数组元素中参数ID添加前缀，例如：array_00_
                let index = k > 9 ? String(k) : '0' + k
                let prefix = 'array_' + index + '_'
                data.thingsModels[i].datatype.arrayParams[k][j].id = prefix + data.thingsModels[i].datatype.arrayParams[k][j].id
                // 图表、实时监测、监测统计分类放置
                if (data.thingsModels[i].datatype.arrayParams[k][j].isChart == 1) {
                  // 图表
                  data.thingsModels[i].datatype.arrayParams[k][j].name = '[' + data.thingsModels[i].name + (k + 1) + '] ' + data.thingsModels[i].datatype.arrayParams[k][j].name
                  data.thingsModels[i].datatype.arrayParams[k][j].datatype.arrayType = 'object'
                  data.chartList.push(data.thingsModels[i].datatype.arrayParams[k][j])
                  if (data.thingsModels[i].datatype.arrayParams[k][j].isHistory == 1) {
                    // 监测统计
                    data.staticList.push(data.thingsModels[i].datatype.arrayParams[k][j])
                  }
                  if (data.thingsModels[i].datatype.arrayParams[k][j].isMonitor == 1) {
                    // 实时监测
                    data.monitorList.push(data.thingsModels[i].datatype.arrayParams[k][j])
                  }
                  data.thingsModels[i].datatype.arrayParams[k].splice(j--, 1)
                }
              }
            }
          } else {
            // 字符串拆分为物模型数组 model=id/name/type/isReadonly/value/shadow
            let values = data.thingsModels[i].value != '' ? data.thingsModels[i].value.split(',') : []
            let shadows = data.thingsModels[i].shadow != '' ? data.thingsModels[i].shadow.split(',') : []
            for (let j = 0; j < data.thingsModels[i].datatype.arrayCount; j++) {
              if (!data.thingsModels[i].datatype.arrayModel) {
                  data.thingsModels[i].datatype.arrayModel = []
              }
              // 数组里面的ID需要添加前缀和索引，例如：array_00_temperature
              let index = j > 9 ? String(j) : '0' + j
              let prefix = 'array_' + index + '_'
              data.thingsModels[i].datatype.arrayModel[j] = {
                id: prefix + data.thingsModels[i].id,
                name: data.thingsModels[i].name,
                type: data.thingsModels[i].type,
                isReadonly: data.thingsModels[i].isReadonly,
                value: values[j] ? values[j] : '',
                shadow: shadows[j] ? shadows[j] : ''
              }
            }
          }
        } else if (data.thingsModels[i].datatype.type == 'object') {
          for (let j = 0; j < data.thingsModels[i].datatype.params.length; j++) {
            // 图表、实时监测、监测统计分类放置
            if (data.thingsModels[i].datatype.params[j].isChart == 1) {
              // 图表
              data.thingsModels[i].datatype.params[j].name = '[' + data.thingsModels[i].name + '] ' + data.thingsModels[i].datatype.params[j].name
              data.chartList.push(data.thingsModels[i].datatype.params[j])
              if (data.thingsModels[i].datatype.params[j].isHistory == 1) {
                // 监测统计
                data.staticList.push(data.thingsModels[i].datatype.params[j])
              }
              if (data.thingsModels[i].datatype.params[j].isMonitor == 1) {
                // 实时监测
                data.monitorList.push(data.thingsModels[i].datatype.params[j])
              }
              data.thingsModels[i].datatype.params.splice(j--, 1)
            }
          }
        } else if (data.thingsModels[i].isChart == 1) {
          // 图表、实时监测、监测统计分类放置
          data.chartList.push(data.thingsModels[i])
          if (data.thingsModels[i].isHistory == 1) {
            // 监测统计
            data.staticList.push(data.thingsModels[i])
          }
          if (data.thingsModels[i].isMonitor == 1) {
            // 实时监测
            data.monitorList.push(data.thingsModels[i])
          }
          // 使用i--解决索引变更问题
          data.thingsModels.splice(i--, 1)
        }
      }
    },
    /**监测统计数据 */
    getStatistic() {
      let color = ['#1890FF', '#91CB74', '#FAC858', '#EE6666', '#73C0DE', '#3CA272', '#FC8452', '#9A60B4', '#ea7ccc']
      for (let i = 0; i < 6; i++) {
        // 设置宽度
        // this.$refs.statisticMap[i].style.width = (document.documentElement.clientWidth - 510) + 'px'
        this.chart[i] = this.$echarts.init(this.$refs.statisticMap[i]);
        console.log( this.chart[i], '999999999999')
        var option;
        option = {
          animationDurationUpdate: 3000,
          tooltip: {
            trigger: 'axis',
          },
          title: {
            left: 'center',
            text: this.staticList[i].name,
            textStyle: {
              color: '#fff'
            }
          },
          grid: {
            top: '14%',
            left: '4%',
            right: '4%',
            bottom: '8%',
            containLabel: true
          },
          // toolbox: {
          //   feature: {
          //     dataZoom: {
          //       yAxisIndex: 'none'
          //     },
          //     restore: {},
          //     saveAsImage: {}
          //   }
          // },
          xAxis: {
            type: 'time',
          },
          yAxis: {
            type: 'value',
          },
          // dataZoom: [
          //   {
          //     type: 'inside',
          //     start: 0,
          //     end: 100
          //   },
          //   {
          //     start: 0,
          //     end: 100
          //   }
          // ],
          series: [{
            name: this.staticList[i].name,
            type: 'line',
            symbol: 'none',
            sampling: 'lttb',
            itemStyle: {
              color: i > 9 ? color[0] : color[i]
            },
            areaStyle: {},
            data: []
          }]
        }
        option && this.chart[i].setOption(option)
      }
    },
    /* 获取监测历史数据*/
    getListHistory() {
      // this.loading = true;
      this.queryParams.serialNumber = this.queryParams.slaveId ? this.deviceInfo.serialNumber + '_' + this.queryParams.slaveId : this.deviceInfo.serialNumber
      if (null != this.daterangeTime && '' != this.daterangeTime) {
        this.queryParams.beginTime = this.daterangeTime[0]
        this.queryParams.endTime = this.daterangeTime[1] +  '23:59'
      }
      listHistory(this.queryParams).then(res => {
        console.log(res, '历史数据')
        for (let key in res.data) {
          for (let i = 0; i < this.staticList.length; i++) {
            if (key == this.staticList[i].id) {
              // 对象转数组
              let dataList = [];
              for (let j = 0; j < res.data[key].length; j++) {
                let item = []
                item[0] = res.data[key][j].time
                item[1] = res.data[key][j].value
                dataList.push(item)
              }
              // 图表显示数据
              this.chart[i].setOption({
                series: [{
                  data: dataList
                }]
              })
            }
          }
        }
        console.log('zoudao这里了')
        // this.loading = false
      })
    },
    getStatistics() {
      let color = ['#1890FF', '#91CB74', '#FAC858', '#EE6666', '#73C0DE', '#3CA272', '#FC8452', '#9A60B4', '#ea7ccc']
      for (let i = 0; i < 6; i++) {
        // 设置宽度
        // this.$refs.statisticMap[i].style.width = (document.documentElement.clientWidth - 510) + 'px'
        let charts =  this.$echarts.init(this.$refs.statisticMap[i]);
         charts.setOption({
            color: ['#d6e7ff'],
            backgroundColor: 'transparent',
            tooltip: {
              trigger: 'item',
              formatter: ' {b} : {a} : {c}'
            },
            grid: {
              left: '5%',
              right: '5%',
              bottom: '5%',
              top: '8%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                data: this.leftCenterX,
                axisTick: {
                  alignWithLabel: true
                },
                axisLabel: {
                  textStyle: {
                    color: '#d6e7ff',
                    fontSize: '14px'
                  }
                }
              }
            ],
            yAxis: [
              {
                type: 'value',
                splitLine: {
                  show: true,
                  lineStyle: {
                    color: ['rgba(255, 255, 255, 0.20)']
                  }
                },
                splitNumber: 3,
                axisLabel: {
                  // formatter: "",
                  textStyle: {
                    color: '#d6e7ff',
                    fontSize: '14px'
                  }
                }
              }
            ],
            series: this.leftCenterY
        }, true)
      }
    },
  },
  mounted() {
    setToken('eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjNiMzE2MDgwLWI1OGYtNDg0MS1hMzgxLTYzOWZhYjA5MWUyZiJ9.uivEcC8dAGBYO27f9zOqCBviq0RfYr7Txtka1ndNA2VUONUhqH04Hbm8gKp3PTz_cOl7Z9YE2PhQ2tZ4ZrROSw')
    this.$EventBus.$on("changeLoaidng", (val) => {
      this.loadingNumber = val;
    });
    this.$EventBus.$on("changeScene", (val) => {
      this.isShowChart = val;
    });
    this.initZThree();
  }
}
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #000;
  position: relative;
  // background-image: url('./../assets/image/BG@2x.png');
  // background-repeat: no-repeat;
  // background-size: 100% 100%;

  .video {
    position: absolute;
    width: 0;
    height: 0;
  }
  .control {
    height: 5%;
    position: fixed;
    bottom: 3%;
    left: 31%;
    z-index: 3;
    float: left;
    font-size: 30px;
  }

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("./../assets/image/mask.png");
    background-size: 100% 100%;
    pointer-events: none;
    z-index: 3;
  }

  .screen {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
  }

  .left {
    width: 600px;
    height: 82%;
    position: fixed;
    top: 160px;
    left: 24px;
    z-index: 3;
  }
  .right {
    width: 600px;
    height: 82%;
    position: fixed;
    top: 160px;
    right: 24px;
    z-index: 3;
  }
  .top {
    width: 984px;
    height: 16%;
    position: fixed;
    top: 10%;
    right: 31%;
    z-index: 3;
    float: left;
  }
  .page_main_left {
    position: absolute;
    left: 20px;
    width: 380px;
    height: 70%;
    bottom: 40px;
    z-index: 999;
  }
  .page_main_right {
    position: absolute;
    right: 20px;
    width: 380px;
    height: 70%;
    bottom: 40px;
    z-index: 999;
  }
  .show_three {
    height: 27%;
    width: 100%;
  } 
}
</style>
<style>
.text-3d {
  padding: 0 10px;
  background: url("./../assets/image/bed_bg.png") no-repeat;
  background-size: 100% 100%;
  color: #fff;
  font-size: 24px;
  line-height: 48px;
  text-align: center;
  cursor: pointer;
}
</style>
